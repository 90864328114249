body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.btn-margin {
  margin: 7px 3px;
}

.profile-area img {
  max-width: 150px;
  margin-bottom: 20px;
}

.panel-body h3 {
  margin-top: 0;
}
